<template>

    <div>

        <!-- **************************** -->
        <!-- El encabezado                -->
        <!-- **************************** -->
        <v-app-bar
            v-if="isLogged"
            elevate-on-scroll
            fixed
            flat
        >
        <v-app-bar-nav-icon @click="drawer = !drawer" ></v-app-bar-nav-icon>

        <v-toolbar-title>
            <v-img contain height="45" src="/images/logo-hno-2017.png"></v-img>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        {{username}}

        <v-tooltip bottom color="green"  >
            <template v-slot:activator="{ on }">
                <v-btn
                    class="ml-5"
                    color="green"
                    small
                    text
                    icon
                    v-on="on"
                    @click="$router.push({path:'/dash'})"
                >
                    <v-icon>mdi-chart-bell-curve-cumulative</v-icon>
                </v-btn>
            </template>
            <span>Estadisticas</span>
        </v-tooltip>

        <v-tooltip bottom color="primary"  >
            <template v-slot:activator="{ on }">
                <v-btn

                    color="primary"
                    small
                    text
                    icon
                    v-on="on"
                    @click="abreComunicados"
                >
                    <v-icon>mdi-chat</v-icon>
                </v-btn>
            </template>
            <span>Comunicados</span>
        </v-tooltip>

        <v-tooltip bottom color="red"  >
            <template v-slot:activator="{ on }">
                <v-btn
                    color="red"
                    small
                    text
                    icon
                    v-on="on"
                    @click="cierraSesion"
                >
                    <v-icon>mdi-logout</v-icon>
                </v-btn>
            </template>
            <span>Cerrar sesión</span>
        </v-tooltip>

        <v-tooltip bottom color="blue">
            <template v-slot:activator="{ on }">
                <v-btn
                    v-if="$store.state.security.roles.includes('ROLE_ADMIN')"
                    color="blue"
                    small
                    text
                    icon
                    :to="{path: '/admin'}"
                    v-on="on"
                >
                    <v-icon>mdi-cog</v-icon>
                </v-btn>
            </template>
            <span>Administrar sitio</span>
        </v-tooltip>

        </v-app-bar>
        <!-- **************************** -->
        <!-- EL menú de navegación        -->
        <!-- **************************** -->
        <v-navigation-drawer
            v-if="isLogged"
            color="primary"
            dark
            app
            v-model="drawer"
            src="/images/NavBack.jpg"
            width="250"
        >
            <v-list-item>
                <v-list-item-content>
                    <v-img
                        contain
                        width="150"
                        src="/images/logo-dependecia87-w.png"
                    />
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-img height="100" contain src="/images/qr-acreditacion-2023.png"></v-img>
            <v-list
                dense
                nav
            >
                <v-list-item
                    v-show="$store.state.security.roles.some(r=>item.roles.indexOf(r) >= 0)"
                    v-for="item in items"
                    :key="item.title"
                    :to="{path: item.route}"
                    link
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <dialog-mensaje
            :dialogModal.sync = "dialogModal"
            :esteComunicado = "esteComunicado"
        />
        <lista-mensajes
            :dialogListModal.sync = "dialogListModal"
        />

        <transition
            name="fade"
            mode="out-in"
        >
            <router-view/>
        </transition>
    </div>
</template>
<script>



export default {
    mounted(){
        this.buscaComunicados()
        //console.log('carga comunicados')
    },
    components:{
        'dialog-mensaje':()=>import('@/views/admin/bb/MuestraComunicadosModal'),
        'lista-mensajes':()=>import('@/views/admin/bb/ListaComunicadosModal')
    },
    data: () => ({
        drawer:true,
        items: [
            { id:10, title: 'Archivo', icon: 'mdi-folder', route: '/archivo',roles:['ROLE_ARCHIVO']},
            { id:20, title: 'Principal', icon: 'mdi-view-dashboard', route: '/dashboard',roles:'ROLE_PACIENTES'},

            { id:30, title: 'Farmacia', icon: 'mdi-pill', route: '/farmacia', roles:['ROLE_FARMACIA']},
            { id:40, title: 'Cuadro Basico', icon: 'mdi-pill', route: '/farmacia/cbasico', roles:['ROLE_PACIENTES']},
            //{ title: 'Trage respiratorio', icon: 'mdi-virus-outline', route: '/urgencias/covid', role:'ROLE_URGENCIAS_COVID'},
            { id:50, title: 'Recursos humanos', icon: 'mdi-account-group', route: '/rhumanos', roles:['ROLE_RH_USUARIO']},
            { id:60, title: 'Estadística', icon: 'mdi-file-chart', route: '/estadistica', roles:['ROLE_ESTADISTICA']},
            { id:70, title: 'Trabajo social', icon: 'mdi-account-multiple', route: '/tsocial', roles:['ROLE_TRABAJO_SOCIAL']},
            { id:80, title: 'Referencias', icon: 'mdi-account-arrow-right', route: '/referencias', roles:['ROLE_REFERENCIAS']},
            { id:90, title: 'Calidad', icon: 'mdi-account-arrow-right', route: '/calidad', roles:['ROLE_CALIDAD']},
            { id:100, title: 'Consulta', icon: 'mdi-calendar', route: '/consulta', roles:['ROLE_CONSULTA']},
            { id:110, title: 'GPC', icon: 'mdi-format-list-bulleted', route: '/GPC', roles:['ROLE_PACIENTES']},
            { id:120, title: 'Quirófanos', icon: 'mdi-doctor', route: '/quirofano', roles:['ROLE_CIRUGIA']},
            { id:130, title: 'Soporte técnico', icon: 'mdi-tools', route: '/mtto', roles:['ROLE_PACIENTES', 'ROLE_MTTO']},


            ],
            dialogModal:false,
            dialogListModal:false,
            esteComunicado:{},
    }),
    methods:{
        cierraSesion(){
            this.$router.push({path:'/'})
            this.$store.commit('security/CIERRA_SESION');
        },
        abreComunicados(){
            this.dialogListModal = true
            //console.log('abreComunicados')
        },
        async buscaComunicados(){
            try{
                let req = await this.$http({
                    method:'POST',
                    url: '/admin/listMensajes',
                    params: {principal:true},
                })
                if (req.data.total > 0){
                    this.dialogModal = true
                    this.esteComunicado = req.data.root[0]
                }
                //this.loadingDatosTodos = false
                //this.datos = req.data.root
                //this.totalRegistros = req.data.total
            } catch(err){
                this.loadingDatosTodos = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    computed:{
        isLogged:{
            get(){return this.$store.getters['security/isLogedIn']},
            set(){}
        },
        username:{
            get(){return this.$store.getters['security/username']},
            set(){}
        }
    },
};
</script>


<style>



.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}


</style>